import React, { useEffect } from "react"
// atm packages
import { AntFriendsInfo, VerifyPhoneForm } from "@components"
import { SignupLayout } from "@layout"
import { usePublishWebEvent } from "@services"
import { TRACKING_ATM } from "@constants"

const SignupVerifyPhone = () => {
  const [publishWebEvent] = usePublishWebEvent()

  useEffect(() => {
    publishWebEvent({ name: TRACKING_ATM.screen_view_reg_phone })
  }, [])

  return (
    <SignupLayout
      hideJoinDownload
      navBarNoLinks
      leftContent={<VerifyPhoneForm />}
      rightContent={<AntFriendsInfo />}
    ></SignupLayout>
  )
}

export default SignupVerifyPhone
